import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/episode-layout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "ep6---dr-michaela-greiler-making-code-reviews-your-superpower"
    }}>{`Ep6 - Dr Michaela Greiler: Making Code Reviews your superpower`}</h1>
    <div className="soundcloud-iframe">
    <iframe className="mb-0" width="100%" height="20" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/917548373&color=%23fc4056&inverse=true&auto_play=false&show_user=true"></iframe>
    </div>
    <Link className="link-button mr-5" to="/transcripts/ep6-dr-michaela-greiler/" mdxType="Link">Transcript</Link>
    <a className="link-button" href="https://youtu.be/x6-WZ3kH4n0">Watch this talk</a>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`On this episode, Dr Michaela Greiler shares with us her journey into tech from Art Schoo. She worked with famous names like Microsoft, Wix, Flutter and more. Through her experience at Microsoft, she found her passion for code reviews and start specialising in this market.`}</p>
    <p>{`We talk about the importance of being physically active to keep a balanced life. Dr Michaela shares excellent tips for anyone that wants to give and do better code reviews, we also talk about how we should leverage nonviolent communication in our day-to-day lives.`}</p>
    <h2 {...{
      "id": "guest-information"
    }}>{`Guest Information`}</h2>
    <div className="episode-card">
    <img src="https://landingintech.com/dr-michaela-greiler.jpg" alt="Dr Michaela Greiler" className="guest-avatar" />
    <div className="mx-8 pt-5">
        <p className="episode-title text-xl">Dr Michaela Greiler</p>
        <p>Code Review Expert</p>
        <p>
            <a href="https://michaelagreiler.com"> Website</a> | <a href="https://twitter.com/mgreiler"> Twitter</a> | <a href="https://se-unlocked.com"> Podcast</a>
        </p>
    </div>
    </div>
    <h2 {...{
      "id": "resources-and-links"
    }}>{`Resources and Links`}</h2>
    <p>{`Through the talk we have mentioned a lot of interesting projects, books, videos and other resources, you can find all about them in this section.`}</p>
    <p><em parentName="p"><strong parentName="em">{`Note`}</strong>{`: Any link to amazon is an affiliate link which means if you buy something from the link, it will not cost you more but we get paid a percentage from amazon which helps with the expenses of running the show.`}</em></p>
    <h3 {...{
      "id": "books"
    }}>{`Books`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/31JXWTE"
        }}>{`Nonviolent Communication - A Language of Life: Life-Changing Tools for Healthy Relationships`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.michaelagreiler.com/code-review-book/"
        }}>{`WIP: Code Review Book`}</a></li>
    </ul>
    <h3 {...{
      "id": "other-resources"
    }}>{`Other Resources`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.khanacademy.org/"
        }}>{`Khan Academy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://se-unlocked.com"
        }}>{`Podcast: Software Engineering Unlocked`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=NNXk_WJzyMI"
        }}>{`Video: 10 Tips for Respectful and Constructive Code Review Feedback`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.michaelagreiler.com/workshops/"
        }}>{`Code Review workshop`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      